











































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AddCustomInvestorGoalModalSuccessViewModel
  from '@/vue-app/view-models/components/goals-dashboard/add-goals/add-custom-investor-goal-modal-success-view-model';

@Component({ name: 'AddCustomInvestorGoalModalSuccess' })
export default class AddCustomInvestorGoalModalSuccess extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  add_custom_investor_goal_success_model = Vue.observable(
    new AddCustomInvestorGoalModalSuccessViewModel(),
  );
}
